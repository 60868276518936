var generic = generic || {};
var site = site || {};

(function ($) {
  Drupal.behaviors.ELB_MyFeed_Loyalty = {
    attach: function (context, settings) {
      // loyalty
      var $container = $('.my-feed-tray--loyalty');

      $container.closest('.drawer-formatter__content').css('min-height', 'auto');
      var userCartCookie = $.cookie('FE_USER_CART');
      var persistentCookie = Drupal.ELB.getJSONCookie('persistent_user_cookie');
      var newsletterOptin = persistentCookie.pc_email_optin - 0;
      var hasLoyalty = persistentCookie.is_loyalty_member - 0;
      var signedIn = site.userInfoCookie.getValue('signed_in') - 0;

      if (hasLoyalty) {
        $container.removeClass('anon');
        $container.addClass('auth loyal');
        userCartCookie =
          userCartCookie?.split('&').map((item) => item.split(':')) || userCartCookie;
        var current_available_points = userCartCookie?.find(
          (item) => item[0] === 'current_available'
        );
        var points_curr = 0;
        if (Array.isArray(current_available_points)) {
          points_curr = current_available_points[1];
        }
        var points = persistentCookie.points || 0;
        var pointsClass = 'my-feed-tray--' + points;
        var level = persistentCookie.loyalty_level || 1;
        var levelClass = 'level__' + level;
        var levelName = persistentCookie.loyalty_level_name || '';
        var tierName = site.translations.product['loyalty_level' + level];

        if (tierName) {
          levelName = tierName;
        }
        levelName = levelName.replace(/\+/g, ' ');
        var nextLevelPoints = persistentCookie.points_to_next_level || 0;
        var nextLevel = persistentCookie.next_level || 2;
        var nextLevelName = persistentCookie.next_level_name || '';

        nextLevelName = nextLevelName.replace(/\+/g, ' ');
        var levelSeparator = $container.find('.level-separator').html() + ' ';
        var levelText = $container.find('.level-text--2').html() + ' ';
        var levelDisplay = level + levelSeparator + levelName;
        var nextLevelDisplay = levelText + nextLevel + levelSeparator + nextLevelName;
        var firstName = persistentCookie.first_name || '';

        firstName = firstName.replace(/\+/g, ' ');

        $container.addClass(levelClass);
        $container.addClass(pointsClass);
        $container.find('.first-name').html('&nbsp;' + firstName);
        $container.find('.point-value').html(points_curr || points);
        $container.find('.current-level').html(levelDisplay);
        $container.find('.next-level').html(nextLevelDisplay);

        if (nextLevelPoints && nextLevelPoints > 0) {
          $container.find('.next-level-points').html(nextLevelPoints);
        } else {
          $container.find('.my-feed-loyalty__status-next').hide();
        }
      } else if (signedIn) {
        $container.removeClass('anon');
        $container.addClass('auth');
      }

      var $newsletterCheckbox = $('.my-feed-loyalty__checkbox-container', $container);

      if (newsletterOptin) {
        $newsletterCheckbox.hide();
        $container.addClass('my-feed-tray--newsletter');
      }

      var loyaltyFeedForm = $('#my-feed-loyalty__form', context);

      $(loyaltyFeedForm)
        .find('.js-my-feed-loyalty__form-submit')
        .on('click', function (e) {
          e.preventDefault();

          // we want to get all the fields in the form because 'serialize' encodes potentially bad emails and decode doesn't decode characters like '+' properly
          var fields = [
            'PC_EMAIL_ADDRESS',
            'LOYALTY_ACTIVE_FLAG',
            'ACCEPTED_LOYALTY_TERMS',
            'PC_EMAIL_PROMOTIONS',
            'PC_EMAIL_PROMOTIONS_PRESENT',
            'LAST_SOURCE',
            '_SUBMIT'
          ];
          var paramObj = {};

          // loop through all the fields and get the values
          $.each(fields, function (index, value) {
            var formField = $('#my-feed-loyalty__form input[name=' + value + ']');

            // for the unchecked PC_EMAIL_PROMOTIONS we want to send empty strings to backend for processing
            if (
              value === 'PC_EMAIL_PROMOTIONS' &&
              formField.is(':checkbox') &&
              !formField.prop('checked')
            ) {
              paramObj[value] = 0;
            } else {
              paramObj[value] = formField.val();
            }
          });

          generic.jsonrpc.fetch({
            method: 'rpc.form',
            params: [paramObj],
            onSuccess: function (jsonRpcResponse) {
              $(document).triggerHandler('email_signup.success', [jsonRpcResponse]);
            },
            onFailure: function (jsonRpcResponse) {
              var errorObjectsArray = jsonRpcResponse.getMessages();
              var errListNode = $('#form--errors--my-feed-loyalty__form', context);

              generic.showErrors(errorObjectsArray, errListNode, loyaltyFeedForm);
            }
          });
        });

      // already logged in
      var $joinBtn = $('.loyalty-offer__join', $container);

      $joinBtn.click(function (event) {
        event.preventDefault();

        if ($container.hasClass('anon')) {
          if (Drupal.settings.globals_variables.loyalty_join_now_btn === 1) {
            // send them to create account
            window.location.href = Drupal.settings.globals_variables.account_enroll_url;
          } else {
            // anon user, non loyal > show overlay
            Drupal.behaviors.ELB_loyalty_offer.showSignupFormNow();
          }
        } else {
          if (Drupal.settings.globals_variables.my_feed_loyalty_join_now_btn === 1) {
            window.location.href = Drupal.settings.globals_variables.my_feed_loyalty_enroll_url;

            return false;
          }
          // recognized user, non loyal
          var params = {};

          params['_SUBMIT'] = 'loyalty_join';
          params['LOYALTY_ACTIVE_FLAG'] = '1';

          var require_loyalty_terms_acceptance = 0;
          var field = $('#my-feed-loyalty__form input[name="_SUBMIT"]'),
            undefined;

          if (field !== undefined && field.length > 0) {
            require_loyalty_terms_acceptance = 1;
            params['_SUBMIT'] = field.val();
            params['profile_loyalty_join'] = '1';
            params['PC_EMAIL_ADDRESS'] = site.userInfoCookie.getValue('email');
            (field = $('#my-feed-loyalty__form input[name="ACCEPTED_LOYALTY_TERMS"]')), undefined;
            if (field !== undefined && field.length > 0) {
              var isUncheckedBox = field.is(':checkbox') && !field.prop('checked');

              params['ACCEPTED_LOYALTY_TERMS'] = isUncheckedBox ? '' : field.val();
            }
          }

          generic.jsonrpc.fetch({
            method: 'rpc.form',
            params: [params],
            onSuccess: function (jsonRpcResponse) {
              // send them to loyalty landing
              // window.location.href = "/account/loyalty/index.tmpl"
              if (require_loyalty_terms_acceptance) {
                var userinfo = {};

                userinfo.registered_user = 1;
                $(document).triggerHandler('join_loyalty.success', userinfo);
              } else {
                $(document).triggerHandler('email_signup.success', [jsonRpcResponse]);
              }
            },
            onFailure: function (jsonRpcResponse) {
              // display error
              if (require_loyalty_terms_acceptance) {
                var messages = jsonRpcResponse.getMessages();

                $.each(messages, function (i, message) {
                  if (message.key === 'required.accepted_loyalty_terms.loyalty_email_signup') {
                    var $formContainer = $('.loyalty-offer__terms', context);
                    var $input = $('.text--checkbox-label', $formContainer);

                    $input.addClass('error');
                  }
                });
              }
            }
          });
        }
      });

      // rollover for special offers link
      var $specialOffersLink = $('.page-sticky-footer__special_offers__link', context);
      var $myFeedTray = $('.my-feed-tray--loyalty', context);

      $specialOffersLink.mouseenter(function () {
        site.drawers.open(
          $('.my-feed-drawer .drawer-formatter__trigger', context),
          $myFeedTray.parents('.drawer-formatter__content', context),
          300,
          false
        );
      });

      var $authTray = $('.my-feed-tray__auth', context);

      if (!$authTray.length) {
        return;
      }
      var $trigger = $('.my-feed-drawer .drawer-formatter__trigger', context);

      $trigger.on('click', function () {
        var myWindow = window.open('/account/beauty_feed.tmpl', '_self');
      });

      // Sign out link returns you to current page and expands special offers (see
      // special_offers.js for cookie handling)

      // Drawers need to be initialized before we can bind events to them:
      site.drawers.init(context);
    }
  };
})(jQuery);
